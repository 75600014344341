<template>
  <div>
    <h3 class="modal__title">Privacy Policy</h3>
    <div class="modal__body">
      <p>
        DeepConvo Inc (DBA Telling.ai) (both together as “we”) are committed to
        protecting and respecting your privacy. This Privacy Policy (“Policy”)
        (together with our Terms and any other documents referred to on it) sets
        out the basis on which any personal data we collect from you, or that
        you provide to us, will be processed by us and how you can get access to
        this information. If in doubt, the primary governing law of this policy
        is that of the state of California, United States.
      </p>
      <h4>1. Purpose of this Policy</h4>
      <p>
        Provides you (the “User”) with access to the online and mobile services
        associated with Telling.ai, including but not limited to, Telling.ai and
        all associated subdomains (the “Website”), collectively the “System”.
        Our privacy policy is written to be compliant with numerous national and
        international laws and frameworks, including (but not limited to)
        <a
          href="https://www.hhs.gov/hipaa/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >HIPAA</a>,
        <a href="https://gdpr.eu/" target="_blank" rel="noopener noreferrer">GDPR</a>, and the
        <a
          href="https://www.privacyshield.gov/welcome"
          target="_blank"
          rel="noopener noreferrer"
        >EU-US Privacy Shield</a>.
      </p>
      <h4>2. Processing your data</h4>
      <p>
        What is the purpose of our processing? We process your data in order to
        provide a personalized pulmonary performance assessment (and to support
        the delivery of that program). What is our legal basis for processing?
        We require consent from all users before processing their data. This
        consent can be withdrawn at any time.
      </p>
      <p>
        <em>What data do we collect?</em>
      </p>
      <p>
        Personal information - We collect and use information like your name,
        email address, and phone number to personalize the assessment and
        communicate with you. You're able to opt out of any external
        communications (i.e., emails, phone calls, and SMS messages) at any
        time. We use information such as your age and gender to assist our
        interpretation of your pulmonary fitness, as your pulmonary fitness can
        vary based on these variables.
      </p>
      <p>
        Health information - We collect information about your pulmonary fitness
        including, but not limited to, a self-reported evaluation of your
        pulmonary fitness in order to deliver our pulmonary fitness assessment.
        We may collect information about pre-existing medical conditions in
        order to ensure the safety and efficacy of the pulmonary performance
        assessment we provide.
      </p>
      <p>
        Electronic identifiers - We may collect information about the devices
        you use to access the System, including (but not limited to) IP address,
        mobile device UDID and IMEI numbers, operating system, browser type, and
        screen size. This information is used to provide you with customer
        support, for system administration, to tailor your experience of the
        System, to report aggregate information internally, and to assist
        communication (e.g., push notifications).
      </p>
      <p>
        Cookies - We may store cookies (small text files managed by your web
        browser) on your computer in order to improve your experience with the
        System. Example uses of these cookies include: recognizing you when you
        return to the System, maintaining data you've entered across multiple
        sessions, and storing information about your personal preferences. You
        may refuse to accept cookies by changing the settings on your device to
        prevent cookies from being set. However, if you select this setting you
        may be unable to access certain parts of the System. Unless you have
        adjusted your browser setting so that it will refuse cookies, our system
        may issue cookies when you visit the System.
      </p>
      <p>
        Non-identifiable information - We may include your data in aggregated
        data sets shared with our research partners. In these sets, your data is
        not personally identifiable, and would be used for supporting
        generalized statements.
      </p>
      <h4>3. Who has access to that data?</h4>
      <p>
        DeepConvo understands that your identifiable health information is
        private and personal and is dedicated to maintaining its confidentiality
        and integrity. As such, we will never sell or rent it, and we have
        policies, procedures, and other safeguards to help protect it from
        improper use and disclosure. We follow a Minimum Necessary Access Policy
        so any required disclosure of your identifiable health information is
        minimized. The following categories describe the ways in which we use
        your identifiable health information and the rare instances that require
        us to disclose it to persons and entities outside of DeepConvo. We have
        not listed every use or disclosure within the categories below, but all
        permitted uses and disclosures will fall within one of the following
        categories. In addition, there are some uses and disclosures that may
        require your specific authorization. DeepConvo does not disclose
        Personal Information to third parties for any purpose materially
        different from the purpose(s) for which it was originally collected.
      </p>
      <p>
        Disclosure at your request - We may disclose information relating to
        your use of the System when requested by you. This disclosure at your
        request may require written authorization by you.
      </p>
      <p>
        Services and Operations - We may use your health information in
        connection with providing services, for our internal operations, which
        include administration, eligibility, planning, analytics and various
        activities that assess and improve the quality and cost effectiveness of
        the service that we deliver to you. Examples are using information about
        you to improve the quality of the service, satisfaction surveys,
        de-identifying health information, customer services and internal
        training.
      </p>
      <p>
        Emails - We may receive a confirmation when you open an email from us,
        or click on a link in an email, if your computer supports this type of
        program. We use this confirmation to help us make emails more
        interesting and helpful. When you receive an email from us, you can opt
        out of receiving further emails by following the included instructions
        to unsubscribe. However, by opting out of further email communications
        after you sign up, you may limit program reminders and other valuable
        program content and components.
      </p>
      <p>
        Reminders and notifications - We may use and disclose your health
        information to contact you as a reminder to interact with, or complete
        tasks relating to your use of the System. You may make changes to the
        format and frequency of these reminders, or cancel these reminders
        and/or notifications by logging into your Telling.ai account on the
        Website, and/or by accessing the native notification settings on your
        mobile device when using the App.
      </p>
      <p>
        Third party service providers - There are some services provided in our
        organization through third party services providers. Examples of third
        party services providers include accounting services, server hosting and
        email delivery providers, business associates, vendors and other
        business partners and reputable companies in the industry who
        subcontract to us where permitted by law. We may disclose your health
        information to our third party services providers so that they can
        perform the job that is required of them. To protect your health
        information, we require appropriate contracts or written agreements be
        in place that safeguard your identifiable health information.
      </p>
      <p>
        Threat to health or safety - We may use and disclose your health
        information when necessary to prevent a serious threat to your health
        and safety or the health and safety of the public or another person. Any
        disclosure, however, would only be to someone able to help prevent the
        threat.
      </p>
      <p>
        As required by law - Certain laws permit or require certain uses and
        disclosures of identifiable health information for example, for public
        health activities, health oversight activities and law enforcement. In
        these instances, DeepConvo will only use or disclose your identifiable
        health information to the extent the law requires.
      </p>
      <p>
        Personal representatives or persons involved with your care - We must
        use and disclose your identifiable health information to anyone who has
        the legal right to act for you (your personal representative) in order
        to administer your rights. We may also use or disclose your identifiable
        health information to a person involved in your care or who helps pay
        for your care, such as a family member, when you are incapacitated or in
        an emergency, or when you agree or fail to object when given the
        opportunity. If you are unavailable or unable to object, we will use our
        best judgment to decide if the disclosure is in your best interests.
        Special rules apply regarding when we may disclose health information to
        family members and others involved in a deceased individual's care. We
        may disclose health information to any persons involved, prior to the
        death, in the care or payment for care of a deceased individual, unless
        we are aware that doing so would be inconsistent with a preference
        previously expressed by the deceased.
      </p>
      <p>
        For research purposes - We may use health information for research
        purposes. While identifiable information will not be published, we may
        publish aggregate information about our users in the context of
        providing public health information and conducting academic research.
      </p>
      <p>
        Transfer of business assets - In the event that we sell or buy any
        business or assets, in which case we may disclose your personal data to
        the prospective seller or buyer of such business or assets. If DeepConvo
        or substantially all of its assets are acquired by a third party,
        personal data held by it about its customers will be one of the
        transferred assets. DeepConvo will ensure that information transferred
        to third parties will only be used in a way that is compliant with
        Privacy Shield Principles, and will remain liable in cases of onward
        transfers to third parties.
      </p>
      <h4>4. How do we store your data?</h4>
      <p>
        Information you provide to us is currently stored in encrypted form on
        secure servers located in the US, which are owned and operated by Amazon
        Web Services (AWS). AWS are industry leaders in the provision of hosting
        services and take security very seriously - you can find out more about
        their security policies and processes in their Security Whitepapers:
        https://aws.amazon.com/security/security-resources/.
      </p>
      <p>
        All passwords are stored in encrypted form and all sensitive traffic is
        transmitted securely via SSL (Secure Sockets Layer) or TLS (Transport
        Layer Security). Your data may be transferred to, and stored at, other
        destinations inside the US by or to staff who work for DeepConvo or one
        of our suppliers. Such staff may be engaged in, among other things the
        provision of support services. By submitting your personal data, you
        agree to this transfer, storing or processing.
      </p>
      <h4>5. Your rights</h4>
      <p>
        Users of the System have certain specific rights with regard to their
        information.
      </p>
      <p>
        Right to access - A user of the System has the right to view all
        personal information that DeepConvo has collected about them, as well as
        the disclosure of this data. In order to receive this data, please
        contact the Security, Privacy, and Compliance Officer. The first copy of
        this information is provided free of charge, and in a portable / common
        electronic form (e.g., CSV file).
      </p>
      <p>
        Right to accuracy - A user of the System has the right to ensure that
        the data we have stored is accurate. In most cases, the system allows
        you to directly modify your own information. However, if there is
        incorrect data within our system that you are not able to change, please
        contact the Security, Privacy, and Compliance Officer and we will work
        directly with you to update this information.
      </p>
      <p>
        Right to deletion - A user of the System has the right to request
        deletion of all data within the system. To request your data be deleted,
        please contact the Security, Privacy, and Compliance Officer. In most
        cases, this request will be completed within 30 days. If circumstances
        require a delay to this deletion, DeepConvo will notify you directly
        explaining the reason for the delay.
      </p>
      <p>
        Note also that in some cases, there may be a legal requirement to hold
        on to your data. Again, DeepConvo will notify you directly if this is
        the case.
      </p>
      <p>
        Right to withdraw consent - A user of the System has the right to
        withdraw their consent at any time by contacting the Security, Privacy,
        and Compliance Officer. Please note that without consent to process your
        data, we will be unable to deliver the Telling.ai program.
      </p>
      <p>
        Right to notification of disclosure - In addition to the right to
        request disclosures of your data specified in the "right to access"
        above, we will notify you as required by law if there has been a breach
        of the security of your identifiable health information.
      </p>
      <p>
        Concerns or complaints - If you believe that any of your rights with
        respect to your or others’ identifiable health information have been
        violated by us, our employees or agents, please communicate with the
        DeepConvo Security, Privacy, and Compliance Officer.
      </p>
      <h4>6. Amending this Policy</h4>
      <p>
        We reserve the right to revise this Policy without notification. Any
        changes or updates will be effective immediately upon posting to
        www.telling.ai.com/privacy. Your continued use of the System constitutes
        your agreement to abide by the Privacy Policy as changed. Under certain
        circumstances (for example, if we expand the ways in which we use your
        personal information beyond the uses stated in our Privacy Policy at the
        time of collection), we may also elect to notify you of changes or
        updates to our Privacy Policy by additional means, such as by sending
        you an email.
      </p>
      <p>
        Questions relating to revisions to this Policy may be addressed to the
        Security, Privacy, and Compliance Officer. DeepConvo's Security,
        Privacy, and Compliance Officer (and Data Controller) can be reached at:
        <a
          href="mailto:privacy@telling.ai"
          target="_blank"
          rel="noopener noreferrer"
        >privacy@telling.ai</a>
      </p>
      <h4>7. Effective Date</h4>
      <p>This Policy is effective as of April 8, 2020</p>

      <div class="modal__footer">
        <button class="button button_secondary button_md" @click="$emit('close')">close</button>
      </div>
    </div>
  </div>
</template>
<script>
import { saveLog } from "@/modules/logger";

export default {
  mounted() {
    saveLog("pop-up-privacy");
  },
};
</script>